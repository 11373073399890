import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import * as Yup from 'yup';
import { Formik } from 'formik';
import classNames from 'classnames';

import 'components/forms/scss/form.scss';
import { LoadingIndicator } from 'components/loadingIndicator';
import { SubmitMessageWithIcon } from 'components/forms';
import { isWhiteSpace } from 'utilities';
import { SOURCERY_ACADEMY } from 'shared/mailchimp-endpoints';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { NewsletterFormComponents } from './NewsletterFormComponents';
import { useMailchimpAcademies } from '../../utilities';

const validationSchema = (translations) => {
  return Yup.object().shape({
    name: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .required(translations.requiredFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    email: Yup.string()
      .email(translations.validEmailFieldText)
      .required(translations.requiredFieldText),
    academies: Yup.array()
      .required(translations.atLeastOneRequired)
      .min('1', translations.atLeastOneRequired),
  });
};

const NewsletterForm = ({ academiesSelectTitle, academiesList, className }) => {
  const SUBMITTED_STATUS = 'submitted';
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const [hasSubmissionError, setSubmissionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const filteredAcademies = useMailchimpAcademies(academiesList);

  const handleSubmit = async (values, actions) => {
    try {
      const academiesToSend = {};
      values.academies.forEach((id) => {
        const selectedAcademy = filteredAcademies.find(
          (academy) => id === academy.id
        );
        academiesToSend[selectedAcademy.name] = selectedAcademy.value;
      });

      const result = await addToMailchimp(
        values.email,
        {
          NAME: values.name,
          ...academiesToSend,
        },
        SOURCERY_ACADEMY || null
      );

      if (result.result !== 'error') {
        actions.setSubmitting(false);
        actions.setStatus(SUBMITTED_STATUS);
      } else {
        actions.setSubmitting(false);
        setSubmissionError(true);
        setErrorMessage(result.msg);
      }
    } catch (e) {
      actions.setSubmitting(false);
      setSubmissionError(true);
    }
  };

  const handleFieldFocus = () => {
    if (hasSubmissionError) {
      setSubmissionError(false);
    }
  };

  const validationTranslations = {
    requiredFieldText: translation['form.validation.isRequired'],
    minFieldText: translation['form.validation.minFieldText'],
    maxFieldText: translation['form.validation.maxFieldText'],
    whiteSpaceFieldText: translation['form.validation.noWhitespace'],
    validEmailFieldText: translation['form.validation.validEmail'],
    atLeastOneRequired: translation['form.validation.isRequiredOneAcademy'],
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        academies:
          filteredAcademies &&
          (filteredAcademies.length !== 1 ? [] : [filteredAcademies[0].id]),
      }}
      validationSchema={validationSchema(validationTranslations)}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <div
            className={classNames(
              'newsletter-form-wrapper',
              className && `${className}`,
              {
                hidden:
                  formikProps.status === SUBMITTED_STATUS ||
                  formikProps.isSubmitting,
              }
            )}
          >
            <NewsletterFormComponents
              hasSubmissionError={hasSubmissionError}
              errorMessage={errorMessage}
              handleFieldFocus={handleFieldFocus}
              academies={filteredAcademies}
              academiesSelectTitle={academiesSelectTitle}
            />
            {formikProps.isSubmitting && <LoadingIndicator />}
            {formikProps.status === SUBMITTED_STATUS && (
              <SubmitMessageWithIcon
                title={translation['form.registration.submitMessageTitle']}
                description={translation['form.registration.submitMessage']}
              />
            )}
          </div>
        );
      }}
    </Formik>
  );
};

NewsletterForm.propTypes = {
  academiesSelectTitle: PropTypes.string,
  academiesList: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string.isRequired,
  formikProps: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    status: PropTypes.string,
  }),
};

export { NewsletterForm };
