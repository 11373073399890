import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import classNames from 'classnames';

import 'components/newsletterSubscription/scss/newsletter-form.scss';
import { FormField, FormCheckboxGroup, SubmitButton } from 'components/forms';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { debounce } from 'utilities';

const NewsletterFormComponents = ({
  hasSubmissionError,
  errorMessage,
  handleFieldFocus,
  academies,
  academiesSelectTitle,
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const [width, setWidth] = useState(window.innerWidth);
  const MOBILE_BREAKPOINT = 768;

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 200);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  const renderFormError = () =>
    hasSubmissionError && (
      <div className="newsletter-form__error">
        {`${translation['form.validation.formError']}${
          errorMessage.length > 0 ? ':' : '.'
        }`}
        <div
          className="newsletter-form__error-message"
          dangerouslySetInnerHTML={{ __html: errorMessage }}
        />
      </div>
    );

  return (
    <Form name="newsletter-form" method="post" className="newsletter-form form">
      {width <= MOBILE_BREAKPOINT && renderFormError()}
      {academies && academies.length > 0 && (
        <>
          <FormCheckboxGroup
            groupName="academies"
            handleFieldFocus={handleFieldFocus}
            options={academies}
            selectionTitle={academiesSelectTitle}
            className={classNames(
              'newsletter-form__checkboxes',
              academies.length === 1 && 'hidden'
            )}
          />
        </>
      )}
      <div className="newsletter-form__inputs">
        {width > MOBILE_BREAKPOINT && renderFormError()}
        <FormField
          onFocus={handleFieldFocus}
          label={translation['form.label.name']}
          name="name"
        />
        <FormField
          onFocus={handleFieldFocus}
          label={translation['contacts.label.email']}
          name="email"
          type="email"
        />
        <SubmitButton>{translation['button.subscribe']}</SubmitButton>
      </div>
    </Form>
  );
};

NewsletterFormComponents.propTypes = {
  hasSubmissionError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  handleFieldFocus: PropTypes.func.isRequired,
  academiesSelectTitle: PropTypes.string,
  academies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export { NewsletterFormComponents };
