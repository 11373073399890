import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Layout } from 'layout';
import { HeroBannerLayout } from 'components/heroBanner';
import { NewsletterForm } from 'components/newsletterSubscription';
import { Game2048 } from './Game2048';
import './scss/styles.scss';

const Game2048Layout = () => {
  const gameJsRefs = [
    '/game-2048/js/config.js',
    '/game-2048/js/data.js',
    '/game-2048/js/utils.js',
    '/game-2048/js/event.js',
    '/game-2048/js/view.js',
    '/game-2048/js/game.js',
    '/game-2048/js/main.js',
  ];

  useEffect(() => {
    gameJsRefs.forEach((src) => {
      const script = document.createElement('script');
      script.src = src;
      document.body.appendChild(script);
    });
  });

  return (
    <Layout darkTheme className="have-full-height">
      <Helmet>
        <title>Discover the bugs | Sourcery Academy for Testers</title>
      </Helmet>
      <HeroBannerLayout
        className="game-2048-layout"
        backgroundImage="/game-2048/image/testers-game-bg.png"
        noGradient
      >
        <div className="game-2048-layout__row">
          <div className="game-2048-layout__column">
            <h1 className="game-2048-layout__title">Discovered bugs</h1>
            <p className="game-2048-layout__desc">
              If software QA seems like something you want to do, write down
              your email to get news from the Devbridge Academy for Testers.
            </p>
            <NewsletterForm
              academiesList={['testers']}
              className="game-2048-layout__form-wrapper"
            />
          </div>
          <div className="game-2048-layout__column game-2048-layout__column--game">
            <Game2048 />
          </div>
        </div>
      </HeroBannerLayout>
    </Layout>
  );
};

export default Game2048Layout;
