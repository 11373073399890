import React from 'react';

const Game2048 = () => (
  <div className="container">
    <div className="heading">
      <h1 className="title">
        <a href="/game-2048/">2048</a>
      </h1>
      <div className="scores-container">
        <div className="score-container">
          <p className="title">SCORE</p>
          <p className="score">0</p>
          <div className="score-addition">+4</div>
        </div>
        <div className="best-container">
          <p className="title">BEST</p>
          <p className="score">66666</p>
        </div>
      </div>
    </div>
    <div className="game-intro">
      <a href="/game-2048/" className="restart-btn">
        New Game
      </a>
      <h2 className="subtitle">Play 2048 Game Online</h2>
      <p className="above-game">
        Join the numbers and get to the <strong>2048</strong> tile!
      </p>
    </div>
    <div className="game-container">
      <div className="grid-container">
        <div className="grid-row">
          <div className="grid-cell" />
          <div className="grid-cell" />
          <div className="grid-cell" />
          <div className="grid-cell" />
        </div>
        <div className="grid-row">
          <div className="grid-cell" />
          <div className="grid-cell" />
          <div className="grid-cell" />
          <div className="grid-cell" />
        </div>
        <div className="grid-row">
          <div className="grid-cell" />
          <div className="grid-cell" />
          <div className="grid-cell" />
          <div className="grid-cell" />
        </div>
        <div className="grid-row">
          <div className="grid-cell" />
          <div className="grid-cell" />
          <div className="grid-cell" />
          <div className="grid-cell" />
        </div>
      </div>
      <div className="tile-container" />
      <div className="failure-container pop-container">
        <p>Game over</p>
      </div>
      <div className="winning-container pop-container">
        <p>Victory!</p>
      </div>
    </div>
  </div>
);

export { Game2048 };
